export const UsersCollection = 'users';
export const LocationsCollection = 'locations';
export const StatsCollection = 'stats';
export const ChecklistCollection = 'checklists';
export const AdminsCollection = 'admins';
export const KeywordsCollection = 'keywords';
export const ReviewCollection = 'reviews';

export const MustChecklistCollection = 'must';
export const EssentialChecklistCollection = 'essential';

export const LanguageCollection = 'languages';

export const AvgLengthReviewStatId = 'avg-count';
export const KeywordsCountStatId = 'keywords-count';
export const ReviewCountStatId = 'review-count';
