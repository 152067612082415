import { useEffect, useState } from 'react';
import { Stats } from '../types/types';
import { ReviewCollection, StatsCollection } from '../collections';
import { fetchCollectionData } from '../firebase-utils';

type Props = {
  userId?: string;
  locationId?: string;
};

interface ReviewsDataResponse {
  reviewStats: Stats[];
}

const useReviewStats = ({ userId, locationId }: Props): ReviewsDataResponse => {
  const [reviewStats, setReviewStats] = useState<Stats[]>([]);

  useEffect(() => {
    if (!userId || !locationId) {
      return;
    }
    const collectionId = `${StatsCollection}/${locationId}/${ReviewCollection}`;
    const { unsubscribe } = fetchCollectionData<Stats>(
      collectionId,
      (data: Stats[]) => {
        setReviewStats(data);
      },
    );

    return () => unsubscribe?.();
  }, [locationId]);

  return { reviewStats };
};

export { useReviewStats };
