import { useAppAuth } from 'src/context/use-app-auth';
import {
  todoLabels as defaultTodoLabels,
  loginLabels as defaultLoginLabels,
  statsLabels as defaultStatsLabels,
  genericLabels as defaultGenericLabels,
  keywordLabels as defaultKeywordLabels,
  dashboardLabels as defaultDashboardLabels,
  alertLabels as defaultAlertLabels,
  reviewLabels as defaultReviewLabels,
  notFoundLabels as defaultNotFoundLabels,
} from './locales/default-en';

import spanishTranslations from './locales/default-es';
import italianTranslations from './locales/default-it';
import { useEffect, useState } from 'react';

export type DataType = number | string;
export interface TranslationLabels {
  // eslint-disable-next-line no-unused-vars
  [key: string]: string | ((data: DataType) => string);
}

function fetchTranslations(languageCode: string) {
  switch (languageCode) {
    case 'es':
      return { ...spanishTranslations };
    case 'it':
      return { ...italianTranslations };
    default:
      return {
        reviewLabels: defaultReviewLabels,
        notFoundLabels: defaultNotFoundLabels,
        alertLabels: defaultAlertLabels,
        statsLabels: defaultStatsLabels,
        genericLabels: defaultGenericLabels,
        loginLabels: defaultLoginLabels,
        dashboardLabels: defaultDashboardLabels,
        keywordLabels: defaultKeywordLabels,
        todoLabels: defaultTodoLabels,
      };
  }
}

const useTranslation = () => {
  const { language } = useAppAuth();
  const [transations, setTranslations] = useState(fetchTranslations(language));

  useEffect(() => {
    setTranslations(fetchTranslations(language));
  }, [language]);

  return transations;
};

export { useTranslation };
