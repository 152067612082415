import { CSSProperties } from 'styled-components';

const t1: CSSProperties = {
  fontSize: '22px',
  lineHeight: '28px',
};

const t2: CSSProperties = {
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
};

const t3: CSSProperties = {
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
};

const l1: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  fontWeight: 500,
};

const l2: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  fontWeight: 500,
};

const l3: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '11px',
  lineHeight: '16px',
  letterSpacing: '0.5px',
  fontWeight: 500,
};

const b1: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '0.5px',
};

const b2: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
};

const b3: CSSProperties = {
  fontFamily: 'Roboto',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.4px',
};

export { t1, t2, t3, l1, l2, l3, b1, b2, b3 };
