import { SxProps } from '@mui/material/styles';
import { GLOBAL_MARGIN_HORIZONTAL } from 'src/app/constants';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  background: brand.background,
  paddingBottom: GLOBAL_MARGIN_HORIZONTAL * 2,
};

const menuContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: '100vh',
  justifyContent: 'space-between',
  paddingLeft: GLOBAL_MARGIN_HORIZONTAL / 2,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL / 2,
  background: brand.background,
  zIndex: 999,
};

const mobileContainer: SxProps = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  paddingLeft: GLOBAL_MARGIN_HORIZONTAL / 2,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL / 2,
  background: brand.background,
};

const contentContainer: SxProps = {
  position: 'relative',
  display: 'flex',
  zIndex: 0,
  flex: 1,
  paddingLeft: GLOBAL_MARGIN_HORIZONTAL * 2,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL * 2,
};

const mobileContentContainer: SxProps = {
  position: 'relative',
  display: 'flex',
  zIndex: 0,
  flex: 1,
  paddingLeft: GLOBAL_MARGIN_HORIZONTAL,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL,
};

export {
  container,
  contentContainer,
  mobileContainer,
  menuContainer,
  mobileContentContainer,
};
