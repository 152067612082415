import React from 'react';
import {
  AreaPlot,
  ChartsXAxis,
  ChartsYAxis,
  ResponsiveChartContainer,
} from '@mui/x-charts';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { TimeSeries } from 'src/api/types/api-types';
import { MetricsData } from 'src/api/hooks/use-metrics';
import { chartLabels } from 'src/text/locales/default-en';
import { text } from './styles';

type Props = {
  data: MetricsData | undefined;
  metricOptions: Array<{
    value: keyof MetricsData;
    label: string;
    text: string;
  }>;
};

const SimplyChart: React.FC<Props> = ({ data, metricOptions }) => {
  const transformTimeSeriesToChartData = (timeSeries?: TimeSeries) => {
    if (!timeSeries) {
      return { xAxisData: [], seriesData: [] };
    }
    const xAxisData = timeSeries.datedValues.map(
      dv =>
        `${dv.date.year}-${String(dv.date.month).padStart(2, '0')}-${String(dv.date.day).padStart(2, '0')}`,
    );
    const seriesData = timeSeries.datedValues.map(dv => Number(dv.value) || 0);
    return { xAxisData, seriesData };
  };

  const [type, setType] = React.useState<keyof MetricsData>(
    metricOptions[0].value,
  );

  const { xAxisData, seriesData } = transformTimeSeriesToChartData(
    data?.[type],
  );

  const totalCount = seriesData.reduce((sum, item) => sum + item, 0);
  const subtitle =
    metricOptions.find(option => option.value === type)?.text ?? '';

  return (
    <Box sx={{ width: '100%' }}>
      <TextField
        select
        value={type}
        onChange={event => setType(event.target.value as keyof MetricsData)}
        label={chartLabels.select}
        sx={{ minWidth: 150 }}>
        {metricOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Box mt={2}>
        <Typography variant="h2">{totalCount}</Typography>
        <Typography sx={text}>{subtitle}</Typography>
      </Box>

      {seriesData.length > 0 ? (
        <ResponsiveChartContainer
          sx={{
            [`.MuiAreaElement-root`]: {
              strokeWidth: 4,
              stroke: '#4285F4',
              fill: '#4285F480',
            },
          }}
          series={[
            {
              type: 'line',
              data: seriesData,
              area: true,
            },
          ]}
          xAxis={[
            {
              data: xAxisData,
              scaleType: 'band',
              id: 'x-axis-id',
            },
          ]}
          yAxis={[
            {
              id: 'y-axis-id',
              min: 0,
              max: Math.max(...seriesData) + 1,
            },
          ]}
          height={350}>
          <AreaPlot />
          <ChartsYAxis
            label={chartLabels.count}
            position="left"
            axisId="y-axis-id"
          />
          <ChartsXAxis
            label={chartLabels.date}
            position="bottom"
            axisId="x-axis-id"
          />
        </ResponsiveChartContainer>
      ) : (
        <Typography sx={{ mt: 2 }}>
          No data available for the selected type.
        </Typography>
      )}
    </Box>
  );
};

export { SimplyChart };
