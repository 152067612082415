import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { content, settings, titleContainer } from '../styles';
import { ChecklistItem } from 'src/api/types/types';
import { useAppAuth } from 'src/context/use-app-auth';
import { ReactComponent as IconSettings } from 'src/assets/svg/settings.svg';
import { b1 } from 'src/themes/custom';

type Props = {
  key: string;
  item: ChecklistItem;
  menuOptions?: string[];
  // eslint-disable-next-line no-unused-vars
  onOptionSelected?: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  onChange: (isChecked: boolean, checklistItem: ChecklistItem) => void;
};

const ChecklistItemComponent: React.FC<Props> = ({
  key,
  item,
  onChange,
  menuOptions = [],
  onOptionSelected,
}) => {
  const { isAdmin } = useAppAuth();
  const [hovered, setHovered] = useState(false);

  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const showOptionsMenu = Boolean(anchor);

  const handleOpenOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Box
      key={key}
      sx={content}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Box sx={titleContainer}>
        {isAdmin && hovered && menuOptions.length > 0 && (
          <Button onClick={handleOpenOptions} sx={settings}>
            <IconSettings width={25} height={25} />
          </Button>
        )}
        <Typography sx={b1}>{item.title}</Typography>
      </Box>

      <Checkbox
        onChange={(event, isChecked) => onChange(isChecked, item)}
        checked={item.checked}
      />

      <Menu
        anchorEl={anchor}
        open={showOptionsMenu}
        onClose={() => setAnchor(null)}>
        {menuOptions.map((item, index) => (
          <MenuItem
            key={item}
            onClick={() => {
              onOptionSelected?.(index);
              setAnchor(null);
            }}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export { ChecklistItemComponent };
