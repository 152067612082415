import { SxProps } from '@mui/material/styles';
import { GLOBAL_MARGIN_HORIZONTAL, HEADER_HEIGHT } from 'src/app/constants';

const container: SxProps = {
  zIndex: 999,
  height: HEADER_HEIGHT,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: GLOBAL_MARGIN_HORIZONTAL,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL,
};

const button: SxProps = {
  fontSize: '16px',
  lineHeight: 'auto',
  fontWeight: 400,
};

export { container, button };
