import React, { useState } from 'react';
import { Screen } from 'src/components/screen';
import { DashboardHeader } from '../components/header/header';
import { Box, Divider, Typography } from '@mui/material';
import {
  container,
  divider,
  infoContainer,
  listContainer,
  mobileInfoContainer,
  mobileWrapper,
} from './styles';
import { useChecklists } from 'src/api/hooks/use-checklists';
import { useAppAuth } from 'src/context/use-app-auth';
import { ChecklistList } from './checklist-list';
import {
  EssentialChecklistCollection,
  MustChecklistCollection,
} from 'src/api/collections';
import { useAddData } from 'src/api/hooks/use-add-data';
import { ChecklistItem } from 'src/api/types/types';
import { useTranslation } from 'src/text';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { ChecklistDialog } from './checklist-dialog';

type DialogProps = {
  item?: ChecklistItem;
  collectionId?: string;
};

const ToDoScreen: React.FC = () => {
  const { user, location } = useAppAuth();
  const { alertLabels, todoLabels } = useTranslation();
  const { isMobile, height } = useWindowDimensions();

  const [selectedChecklist, setSelectedChecklist] = useState<DialogProps>();

  const { addToDo, updateToDo, deleteToDo } = useAddData();
  const { strength, essentialChecklist, mustChecklist } = useChecklists({
    locationId: location?.key ?? '',
  });

  const handleOnChangeCheck = async (
    isChecked: boolean,
    checklistItem: ChecklistItem,
    collectionId: string,
  ) => {
    if (!user || !location) {
      return;
    }

    await updateToDo({
      isChecked,
      todoDocumentId: checklistItem.key,
      todoCollectionId: collectionId,
      locationId: location.key,
    });
  };

  const handleOnAddTodo = async (value: string) => {
    if (!user || !location || !value) {
      return;
    }

    await addToDo({
      title: value,
      locationId: location.key,
      collectionKey: EssentialChecklistCollection,
    });
  };

  const handleOnMustAddTodo = async (value: string) => {
    if (!user || !location || !value) {
      return;
    }

    await addToDo({
      title: value,
      locationId: location.key,
      collectionKey: MustChecklistCollection,
    });
  };

  const handleOnDeleteToDo = (item: ChecklistItem) => {
    if (window.confirm(alertLabels.deleteToDo(item.title))) {
      deleteToDo({
        key: item.key ?? '',
        locationId: location?.key ?? '',
      });
    }
  };

  const handleOnEditToDo = (item: ChecklistItem, collectionId: string) => {
    setSelectedChecklist({ item, collectionId });
  };

  const containerStyle = {
    ...listContainer,
    ...(isMobile ? mobileWrapper : {}),
  };

  return (
    <Screen>
      <Box sx={{ ...container, minHeight: height }}>
        <DashboardHeader selection={1} />
        <Box mt={8} sx={isMobile ? mobileInfoContainer : infoContainer}>
          <Typography variant="h6">{todoLabels.progress}</Typography>
          <Typography mt={2} variant="h2">
            {strength}/{essentialChecklist.length + mustChecklist.length}
          </Typography>
          <Box sx={divider}>
            <Divider />
          </Box>
          <Box sx={containerStyle}>
            <ChecklistList
              onAdd={handleOnAddTodo}
              onChange={(isChecked, checklistItem) =>
                handleOnChangeCheck(
                  isChecked,
                  checklistItem,
                  EssentialChecklistCollection,
                )
              }
              title={todoLabels.essentialInfo}
              data={essentialChecklist}
              onEdit={item =>
                handleOnEditToDo(item, EssentialChecklistCollection)
              }
              onDelete={handleOnDeleteToDo}
              menuOptions={[todoLabels.edit, todoLabels.delete]}
            />
            <Box width={100} height={isMobile ? 20 : 0}></Box>
            <ChecklistList
              onAdd={handleOnMustAddTodo}
              onChange={(isChecked, checklistItem) =>
                handleOnChangeCheck(
                  isChecked,
                  checklistItem,
                  MustChecklistCollection,
                )
              }
              title={todoLabels.mustHaves}
              data={mustChecklist}
              onEdit={item => handleOnEditToDo(item, MustChecklistCollection)}
              onDelete={handleOnDeleteToDo}
              menuOptions={[todoLabels.edit, todoLabels.delete]}
            />
          </Box>
        </Box>
      </Box>

      {selectedChecklist?.item && selectedChecklist?.collectionId && (
        <ChecklistDialog
          collectionId={selectedChecklist.collectionId}
          item={selectedChecklist.item}
          onClose={() => setSelectedChecklist(undefined)}
        />
      )}
    </Screen>
  );
};

export { ToDoScreen };
