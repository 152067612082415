import { createTheme } from '@mui/material/styles';
import { brand } from './palette';

const isDarkMode = false;
const palette = isDarkMode ? brand : brand;

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: palette.primary,
      light: palette.border,
    },
    secondary: {
      main: palette.secondary,
      light: palette.s60,
    },
    error: { main: palette.primary },
    warning: { main: palette.primary },
    info: { main: palette.primary },
    success: { main: palette.primary },
  },
  typography: {
    allVariants: {
      fontFamily: 'Questrial',
      color: palette.primary,
      textAlign: 'left',
      letterSpacing: 1,
      fontWeight: 400,
    },
    h1: {
      fontSize: '57px',
      lineHeight: '64px',
      letterSpacing: '-0.25px',
    },
    h2: {
      fontSize: '45px',
      lineHeight: '52px',
    },
    h3: {
      fontSize: '36px',
      lineHeight: '44px',
    },
    h4: {
      fontSize: '32px',
      lineHeight: '40px',
    },
    h5: {
      fontSize: '28px',
      lineHeight: '36px',
    },
    h6: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            opacity: 0.8,
          },
          fontFamily: 'Roboto',
          fontWeight: 500,
          fontSize: 14,
          lineHeight: '20px',
          letterSpacing: '0.1px',
          textTransform: 'capitalize',
          '&.MuiButton-text': {
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            opacity: 0.5,
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
export { theme };
