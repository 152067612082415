import {
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  Unsubscribe,
} from 'firebase/auth';
import { auth } from '../client';
import { AuthStatus, Role, SimplyCityUser } from '../types/types';
import { authenticateUser } from './firebase-service';
import { fetchBusinessAccount } from '../google-auth';
import {
  AdminsCollection,
  LocationsCollection,
  UsersCollection,
} from '../collections';
import {
  fetchDocuments,
  updateDocument,
  updateDocuments,
} from '../firebase-utils';
import { BusinessLocation } from '../types/business-types';
// import { UsersCollection } from '../collections';

export interface AuthListener {
  // eslint-disable-next-line no-unused-vars
  onUserUpdated: (user: SimplyCityUser) => void;
  // eslint-disable-next-line no-unused-vars
  onError: (error: Error) => void;
}

class AuthService {
  private user: SimplyCityUser | undefined;
  private listener: AuthListener | undefined;
  private token: string | undefined;
  private authUnsubscribe: Unsubscribe | undefined;

  constructor() {
    this.authUnsubscribe = this.addStateChangeListener();
  }

  public async signInWithGoogle() {
    try {
      if (this.authUnsubscribe) {
        this.authUnsubscribe();
      }
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/business.manage');
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      this.token = credential?.accessToken;
      this.authUnsubscribe = this.addStateChangeListener();
    } catch (error) {
      this.listener?.onError(error as Error);
    }
  }

  public signOut() {
    return auth.signOut();
  }

  public getUser() {
    return this.user;
  }

  public addListener(listener: AuthListener) {
    this.listener = listener;
    return () => {
      this.listener = undefined;
    };
  }

  /**
   *
   * @private
   *
   */

  private notifyListeners() {
    if (!this.listener || !this.user) {
      return;
    }

    this.listener.onUserUpdated(this.user);
  }

  private async updateUser(user: SimplyCityUser) {
    if (this.token) {
      const { account, locations } = await fetchBusinessAccount(this.token);
      if (account) {
        user.account = account;
      }

      user.token = this.token;

      if (locations) {
        user.locations = locations.map(location => {
          return { ...location, businessIds: [user.key] };
        });
      }

      user.locations = user.locations.map(location => {
        return { ...location, businessIds: [user.key] };
      });

      const userLocationCollectionId = `${UsersCollection}/${user.key}/${LocationsCollection}`;
      await updateDocuments<BusinessLocation>(
        userLocationCollectionId,
        user.locations,
      );

      await updateDocuments<BusinessLocation>(
        LocationsCollection,
        user.locations,
      );

      if (user.role === Role.admin) {
        const { data } =
          await fetchDocuments<BusinessLocation>(LocationsCollection);
        user.locations = data ?? [];

        const userDocumentId = `${AdminsCollection}/${user.key}`;
        await updateDocument(
          { token: this.token, account: user.account },
          userDocumentId,
        );
      } else {
        const userDocumentId = `${UsersCollection}/${user.key}`;
        await updateDocument(
          { token: this.token, account: user.account },
          userDocumentId,
        );
      }
    }
    this.setUser(user);
  }

  private setUser(user: SimplyCityUser) {
    this.user = user;
    this.notifyListeners();
  }

  private addStateChangeListener(): Unsubscribe {
    return onAuthStateChanged(auth, async user => {
      try {
        if (!user) {
          throw new Error(
            'Unable to authenticate. User or token are not defined',
          );
        }
        const { isAuthenticated, data: userResult } =
          await authenticateUser(user);
        if (!isAuthenticated || !userResult) {
          throw new Error('Authentication failed', {
            cause: AuthStatus.notFound,
          });
        }
        this.updateUser(userResult);
      } catch (error) {
        this.listener?.onError(error as Error);
      }
    });
  }
}

const authService = new AuthService();
export { authService };
