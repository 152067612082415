import { SxProps } from '@mui/material';

const container: SxProps = {
  display: 'flex',
};

const text: SxProps = {
  fontSize: '12px',
};

export { container, text };
