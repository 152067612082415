import { Box, Typography } from '@mui/material';
import React from 'react';
import { Screen } from 'src/components/screen';
import { contentContainer, title, detail, root } from './styles';
import { useTranslation } from 'src/text';
import { ReactComponent as IconWaitlist } from 'src/assets/svg/waitlist.svg';
import { b2 } from 'src/themes/custom';

const NotFound: React.FC = () => {
  const { notFoundLabels } = useTranslation();

  return (
    <Screen contentContainerStyle={root}>
      <Box sx={contentContainer}>
        <IconWaitlist width={50} height={30} />
        <Typography variant="h6" mt={3} sx={title}>
          {notFoundLabels.waitlist}
        </Typography>
        <Typography mt={3} sx={[detail, b2]}>
          {notFoundLabels.emailUs}
        </Typography>
      </Box>
    </Screen>
  );
};

export { NotFound };
