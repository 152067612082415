import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { select, fullWidthSelect, text } from './styles';
import { useTranslation } from 'src/text';
import { BusinessLocation } from 'src/api/types/business-types';
import { AppStateID, useAppAuth } from 'src/context/use-app-auth';
import { useWindowDimensions } from 'src/utils/ui/dimensions';

const LocationSelect = () => {
  const { genericLabels, dashboardLabels } = useTranslation();
  const { location, updateState, user } = useAppAuth();
  const { isMobile } = useWindowDimensions();
  const locations: BusinessLocation[] = user?.locations ?? [];

  const handleLocationSelection = (selectedLocationName: string) => {
    const selectedLocation = locations.find(
      loc => loc.title === selectedLocationName,
    );
    if (selectedLocation) {
      updateState(AppStateID.Location, selectedLocation);
    }
  };

  return (
    <TextField
      select
      sx={isMobile ? [fullWidthSelect, text] : [select, text]}
      focused={false}
      label={dashboardLabels.businessLocation}
      value={location?.title ?? genericLabels.na}
      onChange={event => handleLocationSelection(event.target.value)}>
      {locations.map(location => (
        <MenuItem sx={text} key={location.name} value={location.title}>
          {location.title}
        </MenuItem>
      ))}
    </TextField>
  );
};

export { LocationSelect };
