import { SxProps } from '@mui/material';
import { brand } from 'src/themes/palette';
import { CSSProperties } from 'styled-components';

const container: SxProps = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
};

const contentContainer: SxProps = {
  borderRadius: 12,
};

const ratingContainer: SxProps = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
};

const error: CSSProperties = {
  color: 'red',
};

const replyButton: SxProps = {
  height: 40,
  width: 150,
  color: 'white',
};

const replyButtonContainer: SxProps = {
  height: 40,
  width: 130,
  display: 'flex',
  color: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: brand.blue,
  borderRadius: 20,
  fontSize: 14,
  paddingLeft: 2,
  paddingRight: 2,
  right: 0,
};

const divider: SxProps = {
  marginTop: 2,
};

const cancelButton: SxProps = {
  height: 40,
  width: 100,
  display: 'flex',
  color: brand.primary,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: brand.lightGray,
  borderRadius: 20,
  fontSize: 14,
  paddingLeft: 2,
  paddingRight: 2,
  right: 0,
};

const commentContainer: SxProps = {
  maxHeight: 130,
  overflowY: 'auto',
  marginTop: 4,
  marginBottom: 1,
};

const progress: SxProps = {
  color: 'white',
};

const progressPrimary: SxProps = {
  color: brand.primary,
};

const replyTextField: SxProps = {
  flex: 1,
  width: '100%',
  marginTop: 2,
  maxHeight: 200,
};

const ratingView: SxProps = {
  marginLeft: 1,
};

export {
  error,
  divider,
  progress,
  container,
  ratingView,
  replyButton,
  cancelButton,
  replyTextField,
  ratingContainer,
  contentContainer,
  commentContainer,
  progressPrimary,
  replyButtonContainer,
};
