import { SxProps } from '@mui/material';
import { GLOBAL_MARGIN_HORIZONTAL } from 'src/app/constants';
import { CSSProperties } from 'styled-components';

const container: SxProps = {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: GLOBAL_MARGIN_HORIZONTAL * 2,
};

const infoContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  marginRight: 8,
};

const mobileInfoContainer: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

const divider: SxProps = {
  marginTop: 3,
  marginBottom: 3,
};

const strengthLabel: Record<string, CSSProperties> = {
  high: {
    color: 'green',
    marginBottom: -4,
    marginRight: 5,
  },
  medium: {
    color: 'orange',
    marginBottom: -4,
    marginRight: 5,
  },
  low: {
    color: 'red',
    marginBottom: -4,
    marginRight: 5,
  },
};

const listContainer: SxProps = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const mobileWrapper: SxProps = {
  flexDirection: 'column',
};

export {
  container,
  divider,
  infoContainer,
  strengthLabel,
  listContainer,
  mobileWrapper,
  mobileInfoContainer,
};
