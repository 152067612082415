import { Box } from '@mui/material';
import React from 'react';
import { authService } from 'src/api/services/auth-service';
import googleBtn from 'src/assets/images/google.png';
import { AppStateID, useAppAuth } from 'src/context/use-app-auth';

const GoogleButton: React.FC = () => {
  const { updateState } = useAppAuth();
  const handleGoogleBtnPress = () => {
    updateState(AppStateID.Loading, true);
    authService.signInWithGoogle();
  };
  return (
    <Box
      sx={{
        cursor: 'pointer',
      }}
      width={250}
      height={47}
      component="img"
      src={googleBtn}
      onClick={handleGoogleBtnPress}
    />
  );
};

export { GoogleButton };
