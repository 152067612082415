import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AuthListener, authService } from 'src/api/services/auth-service';
import { BusinessLocation } from 'src/api/types/business-types';
import { AuthStatus, Role, SimplyCityUser } from 'src/api/types/types';
import { getDateString } from 'src/utils/app/string';

export enum AppStateID {
  // eslint-disable-next-line no-unused-vars
  Location,
  // eslint-disable-next-line no-unused-vars
  Loading,
  // eslint-disable-next-line no-unused-vars
  Language,
  // eslint-disable-next-line no-unused-vars
  Date,
}

type UpdateProp = BusinessLocation | string | boolean;

interface AppContextType {
  isAdmin: boolean;
  strength: number;
  isLoading: boolean;
  hasAuthError: boolean;
  date: string;
  language: string;
  isUserLoggedIn: boolean;
  user?: SimplyCityUser;
  location?: BusinessLocation;
  // eslint-disable-next-line no-unused-vars
  updateState: (updateId: AppStateID, value: UpdateProp) => void;
}

const initialContext: AppContextType = {
  isAdmin: false,
  hasAuthError: false,
  isLoading: true,
  date: '',
  strength: 0,
  language: 'en',
  isUserLoggedIn: false,
  updateState: () => {},
};

const Context = createContext<AppContextType>(initialContext);

export const useAppAuth = (): AppContextType => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useAppAuth must be used within AuthProvider');
  }

  return context;
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [hasAuthError, setHasAuthError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [date, setDate] = useState<string>(getDateString());
  const [user, setUser] = useState<SimplyCityUser | undefined>(undefined);
  const [location, setLocation] = useState<BusinessLocation | undefined>();
  const [language, setLanguage] = useState<string>('en');

  const strength = 10;
  const isAdmin = user?.role === Role.admin;

  const updateState = (updateId: AppStateID, value: UpdateProp) => {
    switch (updateId) {
      case AppStateID.Location:
        setLocation(value as BusinessLocation);
        break;
      case AppStateID.Language:
        setLanguage(value as string);
        break;
      case AppStateID.Date:
        setDate(value as string);
        break;
      case AppStateID.Loading:
        setIsLoading(value as boolean);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const listener: AuthListener = {
      onUserUpdated: (userData: SimplyCityUser) => {
        setUser(userData);
      },
      onError: (error: Error) => {
        console.error(error);
        setIsLoading(false);
        setUser(undefined);
        authService.signOut();
        if (error.cause === AuthStatus.notFound) {
          setHasAuthError(true);
        }
      },
    };

    const unsubscribe = authService.addListener(listener);

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user && !location && user.locations.length > 0) {
      setLocation(user.locations[0]);
    }

    if (user) {
      setIsLoading(false);
    }
  }, [user, location, user?.locations]);

  const contextValue: AppContextType = useMemo(
    () => ({
      date,
      user,
      isAdmin,
      language,
      location,
      strength,
      isLoading,
      updateState,
      hasAuthError,
      isUserLoggedIn: !!user,
    }),
    [date, user, location, strength, isLoading, language],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
