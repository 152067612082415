import React from 'react';
import { Box, Button } from '@mui/material';
import { button, container, logoContainer, logoImage } from './styles';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { LanguageSelect } from 'src/components/language-select';

import { useWindowDimensions } from 'src/utils/ui/dimensions';

import { ReactComponent as Menu } from 'src/assets/svg/menu.svg';
import logo from 'src/assets/images/logo.png';
import { HeaderButtonsList } from './header-buttons';

type Props = {
  onOpenMenu: () => void;
};

const HeaderBar: React.FC<Props> = ({ onOpenMenu }) => {
  const { isMobile } = useWindowDimensions();

  return (
    <Box sx={container}>
      {conditionalRender(
        isMobile,
        <Button onClick={onOpenMenu} sx={button}>
          <Menu width={30} height={30} />
        </Button>,
        <HeaderButtonsList />,
      )}

      <Box sx={!isMobile ? logoContainer : {}}>
        <img src={logo} alt="Logo" style={logoImage} />
      </Box>
      {!isMobile && (
        <Box>
          <LanguageSelect />
        </Box>
      )}

      {isMobile && <Box sx={button}></Box>}
    </Box>
  );
};

export { HeaderBar };
