import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import {
  container,
  countContainer,
  countStyle,
  movementStyle,
  movementStyleNegative,
  settings,
  titleContainer,
  titleStyle,
} from './styles';
import { useAppAuth } from 'src/context/use-app-auth';
import { ReactComponent as IconSettings } from 'src/assets/svg/settings.svg';
import { useTranslation } from 'src/text';
import { b1, b2 } from 'src/themes/custom';
import { fetchStatsDetail } from 'src/text/locales/utilities';

type Props = {
  statsId?: string;
  title: string;
  subtitle?: string;
  strength: number;
  movement: number;
  competitorsCount: number;
  isReviewStats?: boolean;
  menuOptions?: string[];
  onOptionSelected?: (index: number, statId: string | undefined) => void;
};

const StatsCard: React.FC<Props> = ({
  statsId,
  title,
  strength,
  subtitle,
  competitorsCount,
  movement,
  isReviewStats = false,
  menuOptions = [],
  onOptionSelected,
}) => {
  const { isAdmin } = useAppAuth();
  const { statsLabels } = useTranslation();
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const baseStrength = strength === 0 ? '--' : strength;
  const statsStrengthText = strength >= 60 ? `${strength}+` : baseStrength;
  const strengthText = isReviewStats ? baseStrength : statsStrengthText;

  const updatedMovement = Math.abs(Math.round(movement ?? 0));

  const handleOpenOptions = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchor(event.currentTarget);
  const handleCloseOptions = () => setAnchor(null);

  const renderMovement = () => (
    <Typography sx={[movementStyle, movement < 0 && movementStyleNegative, b2]}>
      {movement >= 0 ? '↑' : '↓'} {updatedMovement} {fetchStatsDetail(statsId)}
    </Typography>
  );

  const renderSubtitle = () => (
    <Typography sx={b2}>
      {(subtitle?.length ?? 0) > 0
        ? subtitle
        : statsLabels.competitorsText(competitorsCount)}
    </Typography>
  );

  return (
    <Box sx={container}>
      <Box sx={titleContainer}>
        <Typography sx={[b1, titleStyle]}>{title}</Typography>
        {isAdmin && menuOptions.length > 0 && (
          <Button onClick={handleOpenOptions} sx={settings}>
            <IconSettings width={25} height={25} />
          </Button>
        )}
      </Box>
      <Box sx={countContainer}>
        <Typography sx={countStyle} variant="h1">
          {!statsId ? '--' : strengthText}
        </Typography>
      </Box>
      <Box>
        {renderSubtitle()}
        {statsId && renderMovement()}
      </Box>

      {menuOptions.length > 0 && (
        <Menu
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={handleCloseOptions}>
          {menuOptions.map((item, index) => (
            <MenuItem
              key={item}
              onClick={() => onOptionSelected?.(index, statsId)}>
              {item}
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};

export { StatsCard };
