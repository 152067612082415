import React from 'react';
import { Box, Typography } from '@mui/material';
import { ChecklistItem, Role } from 'src/api/types/types';
import { container, wrapper } from './styles';
import { AddFieldComponent } from 'src/components/add-field';
import { useAppAuth } from 'src/context/use-app-auth';
import { ChecklistItemComponent } from './checklist-item';
import { useTranslation } from 'src/text';

type Props = {
  title: string;
  data: ChecklistItem[];
  // eslint-disable-next-line no-unused-vars
  onChange: (isChecked: boolean, checklistItem: ChecklistItem) => void;
  // eslint-disable-next-line no-unused-vars
  onAdd?: (value: string) => void;
  // eslint-disable-next-line no-unused-vars
  onEdit?: (item: ChecklistItem) => void;
  // eslint-disable-next-line no-unused-vars
  onDelete?: (item: ChecklistItem) => void;
  menuOptions?: string[];
};

const ChecklistList: React.FC<Props> = ({
  title,
  data,
  onAdd,
  onChange,
  onEdit,
  onDelete,
  menuOptions,
}) => {
  const { user } = useAppAuth();
  const { todoLabels } = useTranslation();

  const isAddingToDoAllowed = onAdd && user?.role === Role.admin;

  const handleOptionsSelected = (option: number, item: ChecklistItem) => {
    if (option === 0) {
      onEdit?.(item);
    } else {
      onDelete?.(item);
    }
  };

  return (
    <Box sx={container}>
      <Typography variant="h3">{title}</Typography>

      {isAddingToDoAllowed && (
        <Box>
          <AddFieldComponent
            allowSpaces
            label={todoLabels.addChecklist}
            onAdd={onAdd}
          />
        </Box>
      )}

      <Box sx={wrapper}>
        {data.map(item => {
          return (
            <ChecklistItemComponent
              key={item.key}
              item={item}
              onChange={onChange}
              menuOptions={menuOptions}
              onOptionSelected={index => handleOptionsSelected(index, item)}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export { ChecklistList };
