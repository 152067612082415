import { SxProps } from '@mui/material/styles';
import { GLOBAL_MARGIN_HORIZONTAL } from 'src/app/constants';

const container: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: GLOBAL_MARGIN_HORIZONTAL,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL,
};

const button: SxProps = {
  fontFamily: 'Questrial',
  fontSize: '20px',
  lineHeight: '22px',
  marginTop: 2,
  marginBottom: 2,
};

export { container, button };
