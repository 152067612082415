import React from 'react';
import { Box, Button } from '@mui/material';
import { button, container } from './styles';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { LanguageSelect } from 'src/components/language-select';
import { useAppAuth } from 'src/context/use-app-auth';
import { useTranslation } from 'src/text';
import { alertLabels } from 'src/text/locales/default-en';
import { authService } from 'src/api/services/auth-service';

const MobileFooterBar: React.FC = () => {
  const { isUserLoggedIn } = useAppAuth();
  const { genericLabels } = useTranslation();

  const handleLogoutPressed = () => {
    const confirmSignOut = window.confirm(alertLabels.signout);
    if (confirmSignOut) {
      authService.signOut();
    }
  };

  return (
    <Box sx={container}>
      <Box>
        <LanguageSelect />
      </Box>
      <Box>
        {conditionalRender(
          isUserLoggedIn,
          <Button sx={button} color="secondary" onClick={handleLogoutPressed}>
            {genericLabels.logout}
          </Button>,
        )}
      </Box>
    </Box>
  );
};

export { MobileFooterBar };
