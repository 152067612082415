import React from 'react';
import { List, ListItem } from '@mui/material';
import { StatsCard } from 'src/components/stats-card';
import { Stats } from 'src/api/types/types';
import { listItem } from '../styles';

interface KeywordListProps {
  data: Stats[];
}

const ReviewList: React.FC<KeywordListProps> = ({ data }) => {
  return (
    <List>
      {data.map(stats => (
        <ListItem key={stats.key ?? stats.title} sx={listItem}>
          <StatsCard
            isReviewStats
            subtitle={stats.subtitle}
            statsId={stats.key ?? stats.title}
            movement={stats.movement}
            strength={Math.round(stats.strength)}
            competitorsCount={stats.competitorsCount}
            title={stats.title}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ReviewList;
