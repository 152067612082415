import { SxProps } from '@mui/material';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  padding: 2,
  flex: 1,
  height: 300,
  borderRadius: 2,
  border: `1px solid ${brand.outline}`,
  backgroundColor: brand.card,
  display: 'flex',
  flexDirection: 'column',
};

const ratingContainer: SxProps = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
};

const generateButton: SxProps = {
  height: 40,
  width: 180,
  color: 'white',
  backgroundColor: brand.blue,
  borderRadius: 20,
  fontSize: 14,
  paddingLeft: 2,
  paddingRight: 2,
  right: 0,
};

const buttonContainer: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginTop: 'auto',
};

const commentContainer: SxProps = {
  maxHeight: 130,
  overflowY: 'auto',
  marginTop: 4,
  marginBottom: 1,
};

const ratingView: SxProps = {
  marginLeft: 1,
};

export {
  container,
  ratingView,
  generateButton,
  buttonContainer,
  ratingContainer,
  commentContainer,
};
