import { SxProps } from '@mui/material';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
};

const content: SxProps = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 2,
  minHeight: 50,
};

const settings: SxProps = {
  width: 40,
  height: 40,
};

const wrapper: SxProps = {
  width: '100%',
  display: 'flex',
  borderRadius: 1,
  marginTop: 3,
  flexDirection: 'column',
  backgroundColor: brand.card,
};

const titleContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export { container, wrapper, content, settings, titleContainer };
