import { KeywordStrength, Stats } from 'src/api/types/types';

function calculateOverallStrength(statsArray: Stats[]): KeywordStrength {
  const sortedStats = [...statsArray].sort((a, b) => b.strength - a.strength);

  const top3Keywords = sortedStats
    .map(stat => stat.strength)
    .filter(strength => strength <= 3);

  const top10Keywords = sortedStats
    .map(stat => stat.strength)
    .filter(strength => strength <= 10);

  let strength: KeywordStrength;
  if (top3Keywords.length > 2) {
    strength = 'high';
  } else if (top10Keywords.length > 0) {
    strength = 'medium';
  } else {
    strength = 'low';
  }

  return strength;
}

export { calculateOverallStrength };
