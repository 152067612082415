import {
  AvgLengthReviewStatId,
  KeywordsCountStatId,
  ReviewCountStatId,
} from 'src/api/collections';
import { statsLabels } from './default-en';

const fetchStatsDetail = (key: string | undefined): string => {
  if (!key) {
    return statsLabels.movementInfo;
  }

  switch (key) {
    case KeywordsCountStatId:
      return statsLabels.keywordStatsInfo;
    case AvgLengthReviewStatId:
      return statsLabels.wordStatsInfo;
    case ReviewCountStatId:
      return statsLabels.reviewStatsInfo;
    default:
      return statsLabels.movementInfo;
  }
};

export { fetchStatsDetail };
