import React, { useState } from 'react';
import { Screen } from 'src/components/screen';
import { DashboardHeader } from '../components/header/header';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ListItem,
  Typography,
} from '@mui/material';
import {
  container,
  infoContainer,
  listItem,
  gridContainer,
  noReviewsContainer,
  noReviewsTitle,
  noReviewsSubtitle,
  progressContainer,
  titleContainer,
} from './styles';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { useReviews } from 'src/api/hooks/use-reviews';
import { useAppAuth } from 'src/context/use-app-auth';
import { ReviewCard } from './review-item';
import { reviewLabels } from 'src/text/locales/default-en';
import { ReviewDialog } from './review-dialog';
import { Review } from 'src/api/types/types';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { ReactComponent as IconNoReviews } from 'src/assets/svg/icon-no-reviews.svg';
import { generateButton } from './review-item/styles';

const ReviewsScreen: React.FC = () => {
  const { isMobile, height } = useWindowDimensions();
  const { location, isAdmin } = useAppAuth();
  const { reviews, loading, resetReviews } = useReviews({
    locationId: location?.key,
  });

  const [resetLoading, setResetLoading] = useState(false);

  const [selectedReview, setSelectedReview] = useState<Review | undefined>();

  const handleOnReviewPress = (review: Review) => {
    setSelectedReview(review);
  };

  const handleResetReviews = async () => {
    setResetLoading(true);
    await resetReviews();
    setResetLoading(false);
  };

  return (
    <Screen>
      <Box sx={{ ...container, minHeight: height }}>
        <DashboardHeader selection={2} />

        <Box mt={8} sx={titleContainer}>
          <Typography variant="h3">{reviewLabels.title}</Typography>
          {isAdmin && (
            <Button sx={generateButton} onClick={handleResetReviews}>
              {resetLoading ? (
                <CircularProgress size={20} />
              ) : (
                reviewLabels.resetReviews
              )}
            </Button>
          )}
        </Box>

        <Box mt={8} sx={infoContainer}>
          {conditionalRender(
            loading,
            <Box sx={progressContainer}>
              <CircularProgress size={40} />
            </Box>,
          )}

          {!loading && (
            <Grid sx={gridContainer} container spacing={2}>
              {reviews.length > 0 &&
                reviews.map(review => (
                  <Grid
                    item
                    xs={12}
                    md={isMobile ? 12 : 6}
                    key={review.reviewId}>
                    <ListItem sx={listItem}>
                      <ReviewCard
                        review={review}
                        onPress={handleOnReviewPress}
                      />
                    </ListItem>
                  </Grid>
                ))}
            </Grid>
          )}

          {!loading && reviews.length === 0 && <NoReviewsPlaceholder />}
        </Box>
      </Box>

      {selectedReview && (
        <ReviewDialog
          review={selectedReview}
          onClose={() => setSelectedReview(undefined)}
        />
      )}
    </Screen>
  );
};

const NoReviewsPlaceholder = () => {
  return (
    <Box sx={noReviewsContainer}>
      <IconNoReviews width={25} height={40} />
      <Typography variant="h6" mt={3} sx={noReviewsTitle}>
        {reviewLabels.noReviewsTitle}
      </Typography>
      <Typography mt={2} sx={noReviewsSubtitle}>
        {reviewLabels.noReviewsSubtitle}
      </Typography>
    </Box>
  );
};

export { ReviewsScreen };
