import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { FIREBASE_CONFIG } from '../app/environment';
import axios from 'axios';

const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);
const firestoreDb = getFirestore(app);

const firebaseAnalytics = getAnalytics(app);
firebaseAnalytics.app.automaticDataCollectionEnabled = true;
const axiosInstance = axios.create();

setPersistence(auth, browserSessionPersistence);
export { firestoreDb, firebaseAnalytics, auth, axiosInstance };
