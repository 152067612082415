import React from 'react';
import { Box, Button } from '@mui/material';
import { button, container } from './styles';
import { CONTACT_US_URL, PRIVACY_URL, TERMS_URL } from 'src/app/constants';
import { useTranslation } from 'src/text';
import { LocationSelect } from 'src/components/location-select';

const MobileButtons: React.FC = () => {
  const { genericLabels } = useTranslation();

  const handleContactPressed = () => {
    window.open(CONTACT_US_URL, '_blank');
  };

  const handleTermsPressed = () => {
    window.open(TERMS_URL, '_blank');
  };

  const handlePrivacyPressed = () => {
    window.open(PRIVACY_URL, '_blank');
  };
  return (
    <Box sx={container}>
      <LocationSelect />
      <Button sx={button} onClick={handleContactPressed}>
        {genericLabels.contactUs}
      </Button>
      <Button sx={button} onClick={handleTermsPressed}>
        {genericLabels.terms}
      </Button>
      <Button sx={button} onClick={handlePrivacyPressed}>
        {genericLabels.privacy}
      </Button>
    </Box>
  );
};

export { MobileButtons };
