import React from 'react';
import { DashboardProvider } from './dashboard-context';
import { KeywordsScreen } from './keywords';

const DashboardScreen = () => {
  return (
    <DashboardProvider>
      <KeywordsScreen />
    </DashboardProvider>
  );
};

export { DashboardScreen };
