import React from 'react';
import { RouteObject, createBrowserRouter, Navigate } from 'react-router-dom';
import { LoginScreen } from '../presentation/login';
import { ToDoScreen } from 'src/presentation/dashboard/to-do';
import { DashboardScreen } from 'src/presentation/dashboard';
import { ReviewsScreen } from 'src/presentation/dashboard/reviews';

const baseRoutesConfig: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/home" />,
  },
  {
    path: '/auth/login',
    element: <Navigate to="/home" />,
  },
  { path: 'home', element: <Navigate to="/home/keywords" /> },
  {
    path: '/home/keywords',
    element: <DashboardScreen />,
  },
  {
    path: '/home/reviews',
    element: <ReviewsScreen />,
  },
  {
    path: '/home/todo',
    element: <ToDoScreen />,
  },
];

const authRoutesConfig: RouteObject[] = [
  {
    path: '/*',
    element: <Navigate to="/auth/login" />,
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: <LoginScreen />,
      },
    ],
  },
];

const createRoutes = (isUserLoggedIn: boolean) =>
  createBrowserRouter(isUserLoggedIn ? baseRoutesConfig : authRoutesConfig);

const defaultRoutes = createBrowserRouter(authRoutesConfig);

export { createRoutes, defaultRoutes };
