/* eslint-disable no-unused-vars */
import { Unsubscribe } from 'firebase/firestore';
import { BusinessAccount, BusinessLocation } from './business-types';

export enum AuthStatus {
  notFound,
}

export enum StatsType {
  daily = 'daily',
  monthly = 'monthly',
  yearly = 'yearly',
  overall = 'overall',
}

export enum Role {
  admin = 'admin',
  customer = 'customer',
}

export interface LatLng {
  latitude: number;
  longitude: number;
}

export interface UpdateResult {
  success: boolean;
  message: string;
}

export interface UpdateResultListener extends UpdateResult {
  unsubscribe?: Unsubscribe;
}

export interface AuthenticationResult {
  isAuthenticated: boolean;
  data?: SimplyCityUser;
}
export interface BusinessAccountResult {
  account?: BusinessAccount;
  locations: BusinessLocation[];
}

export interface BaseFirebaseResult {
  success: boolean;
  message?: string;
}

export interface AddDocumentResult extends BaseFirebaseResult {
  documentId: string;
}

export interface FetchListResult<T> extends BaseFirebaseResult {
  data?: T[];
}
export interface FetchResult<T> extends BaseFirebaseResult {
  data?: T;
}

export interface SimplyCityUser {
  role: Role;
  enabled: boolean;
  locations: BusinessLocation[];
  account: BusinessAccount;
  key: string;
  name: string;
  token?: string;
  language: string;
  competitorsCount: number;
}

export interface Stats {
  key?: string;
  title: string;
  subtitle: string;
  strength: number;
  movement: number;
  overallMovement: number;
  competitorsCount: number;
}

export interface ChecklistItem {
  key: string;
  title: string;
  checked: boolean;
  updatedAt: string;
}

interface Reviewer {
  userId: string;
  profilePhotoUrl: string;
  displayName: string;
  isAnonymous: boolean;
}

interface ReviewReply {
  comment: string;
  updateTime: string;
}

export enum StarRating {
  STAR_RATING_UNSPECIFIED = 'STAR_RATING_UNSPECIFIED',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
}

export interface Review {
  name: string;
  reviewId: string;
  reviewer: Reviewer;
  starRating: StarRating | string;
  comment: string;
  createTime: string;
  updateTime: string;
  reviewReply?: ReviewReply;
}

export type KeywordStrength = 'high' | 'medium' | 'low';
