import { SxProps, Theme } from '@mui/system';

const mergeStyles = (
  style1: SxProps<Theme>,
  style2?: SxProps<Theme>,
): SxProps<Theme> | undefined => {
  if (!style1 && !style2) return undefined;

  if (!style1) return style2;
  if (!style2) return style1;

  if (Array.isArray(style1) && Array.isArray(style2)) {
    return [...style1, ...style2];
  }

  if (Array.isArray(style1)) {
    return [...style1, style2];
  }
  if (Array.isArray(style2)) {
    return [style1, ...style2];
  }

  const style = {
    ...(style1 as SxProps<Theme>),
    ...(style2 as SxProps<Theme>),
  };
  return style;
};

export { mergeStyles };
