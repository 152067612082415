import { SxProps } from '@mui/material';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
};

const addKeywordField: SxProps = {
  flex: 1,
  marginRight: '15%',
};

const addButton: SxProps = {
  height: 40,
  width: 92,
  color: brand.tertiary,
  backgroundColor: brand.blue,
  borderRadius: 20,
};

export { container, addButton, addKeywordField };
