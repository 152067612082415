import { AI_API_KEY } from 'src/app/environment';
import { Review } from '../types/types';
import { GoogleGenerativeAI } from '@google/generative-ai';

const genAI = new GoogleGenerativeAI(AI_API_KEY ?? '');

const generateReviewReply = async (review: Review): Promise<string> => {
  try {
    const model = genAI.getGenerativeModel({ model: 'gemini-1.5-flash' });
    const result = await model.generateContent([
      `Generate a single, concise response to the Google review "${review.comment}". Ensure the response is grammatically correct, polite, and addresses the specific points raised in the review. Avoid adding any comments or personal opinions. If necessary, use humor or empathy to connect with the reviewer. Keep the response language appropriate for the business and target audience. Use the original language. If the review input is empty simply make a short thank you response message`,
    ]);

    // Assuming the result contains the generated reply in a specific format
    const generatedReply = result?.response?.text() || '';
    return generatedReply;
  } catch (error) {
    console.error('Error generating review reply:', error);
    throw error;
  }
};

export { generateReviewReply };
