import React, { PropsWithChildren, useContext, useState } from 'react';

type ContextValue = {
  isGridOverlayEnabled: boolean;
  setIsGridOverlayEnabled: (isEnabled: boolean) => void;
};

const defaultValues = {
  isGridOverlayEnabled: false,
  setIsGridOverlayEnabled: () => {},
};

export const Context = React.createContext<ContextValue>(defaultValues);

export const GridOverlayProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isOverlayEnabled, setIsOverlayEnabled] = useState(
    defaultValues.isGridOverlayEnabled,
  );

  return (
    <Context.Provider
      value={{
        isGridOverlayEnabled: isOverlayEnabled,
        setIsGridOverlayEnabled: (enabled: boolean) =>
          setIsOverlayEnabled(enabled),
      }}>
      {children}
    </Context.Provider>
  );
};

export const useGridOverlay = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useGridOverlay must be wrapped within GridOverlayProvider',
    );
  }

  return context;
};
