import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  padding: 2,
  minWidth: 270,
  flex: 1,
  height: 180,
  borderRadius: 2,
  border: `1px solid ${brand.outline}`,
  backgroundColor: brand.card,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const titleContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  marginRight: -2,
  alignItems: 'center',
  justifyContent: 'space-between',
};

const settings: SxProps = {
  width: 40,
  height: 40,
};

const titleStyle: CSSProperties = {
  textTransform: 'capitalize',
};

const countContainer: SxProps = {
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
};

const countStyle: CSSProperties = {};
const movementStyle: CSSProperties = {
  color: brand.green,
};

const movementStyleNegative: CSSProperties = {
  color: 'red',
};

export {
  settings,
  container,
  titleStyle,
  countStyle,
  titleContainer,
  countContainer,
  movementStyle,
  movementStyleNegative,
};
