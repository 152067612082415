import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  TextField,
  Box,
  CircularProgress,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Review } from 'src/api/types/types';
import {
  progress,
  container,
  replyButton,
  cancelButton,
  replyTextField,
  contentContainer,
  replyButtonContainer,
  progressPrimary,
  divider,
  error,
} from './styles';
import {
  alertLabels,
  genericLabels,
  reviewLabels,
} from 'src/text/locales/default-en';
import { ReviewInfoBox } from '../review-item';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { useReviews } from 'src/api/hooks/use-reviews';
import { useAppAuth } from 'src/context/use-app-auth';
import { generateReviewReply } from 'src/api/services/ai-service';
import { b3 } from 'src/themes/custom';

type Props = {
  review: Review;
  onClose: () => void;
};

const ReviewDialog: React.FC<Props> = ({ review, onClose }) => {
  const { location, user } = useAppAuth();
  const [errorText, setErrorText] = useState('');
  const { loading, addReview } = useReviews({
    locationId: location?.key,
    fetchData: false,
  });
  const [generatingReply, setGeneratingReply] = useState(false);

  const [reply, setReply] = useState('');

  useEffect(() => {
    handleGenerateReply();
  }, []);

  const handleGenerateReply = () => {
    setGeneratingReply(true);
    generateReviewReply(review)
      .then(message => {
        setReply(message);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setGeneratingReply(false);
      });
  };

  const handleReplyChange = (event: { target: { value: string } }) => {
    setReply(event.target.value);
  };

  const handleOnReply = async () => {
    setErrorText('');
    if (!location?.key || !user) {
      return;
    }
    const response = await addReview({
      reviewText: reply,
      reviewId: review.reviewId,
      userId: user.key,
      locationId: location.key,
    });

    if (response) {
      onClose();
    } else {
      setErrorText(alertLabels.error);
    }
  };

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      sx={container}
      open={true}
      onClose={onClose}>
      <DialogContent sx={contentContainer}>
        <ReviewInfoBox review={review} showRatingStar={false} />
        <Divider sx={divider} />
        <TextField
          focused
          sx={replyTextField}
          value={reply}
          multiline
          label={reviewLabels.suggestedReply}
          onChange={handleReplyChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {generatingReply && (
                  <CircularProgress size={20} sx={progressPrimary} />
                )}
              </InputAdornment>
            ),
          }}
        />
        {errorText && <Typography sx={[error, b3]}>{errorText}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button sx={cancelButton} onClick={onClose}>
          {genericLabels.cancel}
        </Button>
        <Box sx={replyButtonContainer}>
          {conditionalRender(
            loading,
            <CircularProgress size={20} sx={progress} />,
            <Button sx={replyButton} onClick={() => handleOnReply()}>
              {reviewLabels.postReply}
            </Button>,
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { ReviewDialog };
