import { SxProps } from '@mui/material/styles';
import { CSSProperties } from 'styled-components';

const root: SxProps = {
  height: '100vh',
  position: 'absolute',
  display: 'flex',
  zIndex: 0,
  width: '100%',
};

const container: SxProps = {
  flex: 1,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const contentContainer: SxProps = {
  ...container,
  flexDirection: 'column',
};

const title: SxProps = {};

const detail: CSSProperties = {
  width: 260,
  textAlign: 'center',
};

export { container, contentContainer, title, detail, root };
