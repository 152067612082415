import { useState, useEffect } from 'react';
import axios from 'axios';
import { TimeSeries } from '../types/api-types';
import { fetchMultiDailyMetrics } from '../services/performance-service';

export interface MetricsData {
  callClicks?: TimeSeries;
  mobileClicks?: TimeSeries;
  websiteClicks?: TimeSeries;
  directionClicks?: TimeSeries;
}

interface MultiDailyMetricsProps {
  locationId: string;
  accessToken: string;
  startDate: Date;
  endDate: Date;
}

interface MultiDailyMetricsResponse {
  data?: MetricsData;
  error?: string;
  loading: boolean;
}

export const useMultiDailyMetrics = ({
  locationId,
  accessToken,
  startDate,
  endDate,
}: MultiDailyMetricsProps): MultiDailyMetricsResponse => {
  const [data, setData] = useState<MetricsData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!locationId || !accessToken) {
      setError('Invalid authentication parameters');
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(undefined);

      try {
        const data = await fetchMultiDailyMetrics(
          locationId,
          accessToken,
          startDate,
          endDate,
        );

        setData(data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(`Failed to fetch data: ${error.response?.statusText}`);
        } else {
          setError('An unexpected error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [locationId, accessToken, startDate, endDate]);

  return { data, loading, error };
};
