import { ReactElement } from 'react';

const conditionalRender = (
  expression: boolean,
  componentWhenTrue: ReactElement,
  componentWhenFalse: ReactElement | null = null,
) => {
  return expression ? componentWhenTrue : componentWhenFalse;
};

export { conditionalRender };
