import { logAxiosError } from 'src/utils/logger/logs';
import { axiosInstance } from './client';
import { BusinessAccountResult } from './types/types';

import { BusinessAccount, BusinessLocation } from './types/business-types';

interface LocationsResponse {
  locations?: BusinessLocation[];
}

interface BusinessResponse {
  accounts: BusinessAccount[];
}

const fetchBusinessAccount = async (
  token: string,
): Promise<BusinessAccountResult> => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const businessResponse = await axiosInstance.get<BusinessResponse>(
      `https://mybusinessbusinessinformation.googleapis.com/v1/accounts/`,
      { headers },
    );

    const businessData = businessResponse.data;
    if (businessData?.accounts?.length > 0) {
      const account = businessData.accounts[0];
      const locationsResponse = await axiosInstance.get<LocationsResponse>(
        `https://mybusinessbusinessinformation.googleapis.com/v1/${account.name}/locations?readMask=name,title,languageCode,storeCode,phoneNumbers,storefrontAddress,websiteUri,serviceArea,latlng,metadata,profile&pageSize=100`,
        { headers },
      );
      const locations =
        locationsResponse.data.locations?.map(location => {
          const key = location.name.replace('locations/', '');
          return { ...location, key };
        }) ?? [];
      return {
        account,
        locations,
      };
    }
  } catch (error) {
    logAxiosError(error);
  }

  return { account: undefined, locations: [] };
};

export { fetchBusinessAccount };
