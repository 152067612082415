import React from 'react';
import { Box, Button, Typography, Rating } from '@mui/material';
import { Review } from 'src/api/types/types';
import { reviewLabels } from 'src/text/locales/default-en';
import { getNumericStarRating } from 'src/utils/app/number';
import { getRelativeTime } from 'src/utils/app/string';
import { useAppAuth } from 'src/context/use-app-auth';
import {
  buttonContainer,
  container,
  commentContainer,
  generateButton,
  ratingContainer,
  ratingView,
} from './styles';
import { b1, b2, t2 } from 'src/themes/custom';

type Props = {
  review: Review;
  showRatingStar?: boolean;
};

type ReviewProps = {
  review: Review;
  onPress: (review: Review) => void;
};

const ReviewInfoBox: React.FC<Props> = ({ review, showRatingStar = true }) => {
  const { language } = useAppAuth();
  const rating = getNumericStarRating(review.starRating);
  return (
    <>
      <Typography sx={t2}>{review.reviewer.displayName}</Typography>
      <Typography mt={1} sx={b2}>
        {getRelativeTime(review.createTime, language)}
      </Typography>
      <Box mt={2} sx={ratingContainer}>
        <Typography variant="h6">
          {rating} {reviewLabels.stars}
        </Typography>
        {showRatingStar && (
          <Rating sx={ratingView} name="read-only" value={rating} readOnly />
        )}
      </Box>
      <Box sx={commentContainer}>
        <Typography sx={b1}>{review.comment}</Typography>
      </Box>
    </>
  );
};

const ReviewCard: React.FC<ReviewProps> = ({ review, onPress }) => {
  return (
    <Box sx={container}>
      <ReviewInfoBox review={review} />
      <Box sx={buttonContainer}>
        <Button sx={generateButton} onClick={() => onPress(review)}>
          {reviewLabels.generateReply}
        </Button>
      </Box>
    </Box>
  );
};

export { ReviewCard, ReviewInfoBox };
