import { SxProps } from '@mui/material';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  borderColor: brand.border,
  overflow: 'hidden',
};

const tab: SxProps = {
  minWidth: 90,
  paddingLeft: 2,
  paddingRight: 2,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
};

const divider: SxProps = {
  height: 40,
  width: 1,
  backgroundColor: brand.border,
};
const icon: SxProps = {
  marginRight: 0.5,
};
const selected: SxProps = {
  backgroundColor: 'secondary.light',
};

export { container, tab, divider, selected, icon };
