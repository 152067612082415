import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppAuth } from 'src/context/use-app-auth';
import { useReviewStats } from 'src/api/hooks/use-review-stats';
import ReviewList from './review-list';
import ReviewGrid from './review-grid';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { useTranslation } from 'src/text';

const ReviewStatsList: React.FC = () => {
  const { user, location } = useAppAuth();
  const { isMobile } = useWindowDimensions();
  const { keywordLabels } = useTranslation();

  const { reviewStats } = useReviewStats({
    userId: user?.key,
    locationId: location?.key,
  });

  if (reviewStats.length === 0) {
    return null;
  }

  return (
    <>
      <Typography mt={4} variant="h3">
        {keywordLabels.reviewStats}
      </Typography>
      <Box mt={2}>
        {isMobile ? (
          <ReviewList data={reviewStats} />
        ) : (
          <ReviewGrid data={reviewStats} />
        )}
      </Box>
    </>
  );
};

export { ReviewStatsList };
