const brand = {
  primary: '#000',
  secondary: '#5B93FF',
  background: '#F4F4F4',
  tertiary: '#EFEFEF',
  border: '#73777F',
  s60: '#D8E3F8',
  green: '#43A047',
  card: '#F8F9FF',
  lightGray: '#D8D8D8',
  outline: '#C3C6CF',
  blue: '#3B608F',
};

export { brand };
