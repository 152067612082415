import { SxProps } from '@mui/material/styles';
import { GLOBAL_MARGIN_HORIZONTAL, HEADER_HEIGHT } from 'src/app/constants';

const container: SxProps = {
  zIndex: 999,
  height: HEADER_HEIGHT,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: GLOBAL_MARGIN_HORIZONTAL,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL,
};

const logoContainer: SxProps = {};

const button: SxProps = {
  height: 50,
  width: 50,
};

const logoImage = {
  width: 150,
  height: 63,
};

export { container, logoContainer, button, logoImage };
