import React from 'react';
import { Box, Button } from '@mui/material';
import { button, container, logoContainer, logoImage } from './styles';

import { ReactComponent as Close } from 'src/assets/svg/close.svg';
import logo from 'src/assets/images/logo.png';

type Props = {
  closeMenu: () => void;
};

const MobileHeaderBar: React.FC<Props> = ({ closeMenu }) => {
  return (
    <Box sx={container}>
      <Button onClick={closeMenu} sx={button}>
        <Close width={20} height={20} />
      </Button>

      <Box sx={logoContainer}>
        <img src={logo} alt="Logo" style={logoImage} />
      </Box>

      <Box sx={button}></Box>
    </Box>
  );
};

export { MobileHeaderBar };
