import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const infoContainer: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
};

const infoContainerWrapper: SxProps = {
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const strengthContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
};

const strengthContainerMobile: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

const addKeywordContainer: SxProps = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  justifyContent: 'space-between',
};

const addKeywordField: SxProps = {
  flex: 1,
  marginRight: '15%',
};

const addButton: SxProps = {
  height: 40,
  width: 80,
  backgroundColor: brand.lightGray,
  borderRadius: 20,
  fontSize: 12,
};

const strengthLabel: Record<string, CSSProperties> = {
  high: {
    color: 'green',
    marginBottom: -4,
    marginRight: 5,
  },
  medium: {
    color: 'orange',
    marginBottom: -4,
    marginRight: 5,
  },
  low: {
    color: 'red',
    marginBottom: -4,
    marginRight: 5,
  },
  none: {
    color: brand.primary,
    marginBottom: -4,
    marginRight: 5,
  },
};
export {
  container,
  addButton,
  infoContainer,
  addKeywordField,
  strengthContainer,
  strengthLabel,
  addKeywordContainer,
  infoContainerWrapper,
  strengthContainerMobile,
};
