import { DataType } from '..';

const notFoundLabels = {
  emailUs: 'Email us at hello@thesimply.city to apply for a test account',
  waitlist: 'Join the waitlist',
};

const genericLabels = {
  contactUs: 'Contact Us',
  logout: 'Logout',
  month: 'Month',
  na: 'N/A',
  add: '+ Add',
  cancel: 'Cancel',
  save: 'Save',
  terms: 'Terms',
  privacy: 'Privacy Policy',
};

const loginLabels = {
  signIn: 'Sign in',
  detail: 'With your business google account',
  new: 'New to SimplyCity?',
};

const dashboardLabels = {
  keywords: 'Keywords',
  toDo: 'To-Do List',
  reviews: 'Reviews',
  businessLocation: 'Business Location',
};

const keywordLabels = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  delete: 'Delete Keyword',
  performanceStats: 'Performance Statistics',
  typeKeyword: 'Type Keyword',
  overallStrength: 'Over all profile strength',
  reviewStats: 'Review Statistics',
  rankings: 'Keywords Rankings',
  description:
    'Keywords for local SEO for a place can vary depending on the specific location, offerings, and target audience. These are some of the keywords found relevant to your business. Keeping track of the progress is key in our system 👍',
  processingKeywords: 'Processing Keywords',
  researchKeywords:
    'The keywords for your businesses are still being researched by the simiplicty team',
};

const statsLabels = {
  movementInfo: 'places (since keyword was added)',
  reviewStatsInfo: 'Reviews (since first login)',
  keywordStatsInfo: 'Keywords (since keywords were added)',
  wordStatsInfo: 'Words (since first login)',
  competitorsText: (count: DataType) => `Out of ${count} local competitors`,
  placesRankingText: (ranking: DataType) => `${ranking} places`,
};

const reviewLabels = {
  title: 'Reply Recent Reviews',
  stars: 'Stars',
  generateReply: 'Generate Reply',
  suggestedReply: 'Suggested Reply',
  postReply: 'Post Reply',
  noReviewsTitle: 'You’re all caught up!',
  resetReviews: 'Fetch Reviews',
  noReviewsSubtitle: 'Well done replying to all of your customers reviews!',
};

const todoLabels = {
  edit: 'Edit item',
  delete: 'Delete item',
  progress: 'Your checklist progress',
  essentialInfo: 'Essential Information',
  mustHaves: 'SimplyCity Must Haves',
  addChecklist: 'Type checklist item',
};

const alertLabels = {
  error: 'An unknown error has occurred',
  signout: 'Are you sure you want to sign out?',
  deleteToDo: (title: DataType) =>
    `Are you sure, you want to delete "${title}"`,
  deleteKeyword: (keyword: DataType) =>
    `Are you sure, you want to delete ${keyword} keyword`,
};

const chartLabels = {
  date: 'Date',
  count: 'Count',
  select: 'Select a type',
  callClicks: 'Call Clicks',
  callsText: 'Calls made from your business profile',
  websiteClicks: 'Website Clicks',
  websiteText: 'Website opened from your business profile',
  businessDirections: 'Business Directions',
  mobileImpressions: 'Mobile Impressions',
  businessDirectionsText: 'Directions opened from your business profile',
  mobileImpressionsText: 'Mobile impressions made from your business profile',
};

export {
  chartLabels,
  alertLabels,
  reviewLabels,
  statsLabels,
  notFoundLabels,
  genericLabels,
  loginLabels,
  dashboardLabels,
  keywordLabels,
  todoLabels,
};
