import { User } from 'firebase/auth';
import { AuthenticationResult, SimplyCityUser } from '../types/types';
import { fetchLocations, fetchUserRoleAndData } from '../firebase-utils';

const authenticateUser = async (user: User): Promise<AuthenticationResult> => {
  try {
    const authenticatedUser = await fetchUserRoleAndData(user);
    const locationsData = await fetchLocations(
      authenticatedUser.role,
      user.uid,
    );

    const userWithLocations: SimplyCityUser = {
      ...authenticatedUser,
      locations: locationsData,
    };

    return {
      isAuthenticated: true,
      data: userWithLocations,
    };
  } catch (error) {
    console.error('Authentication error:', error);
    return {
      isAuthenticated: false,
    };
  }
};

export { authenticateUser };
