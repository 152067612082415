import React from 'react';
import flowRight from 'lodash.flowright';
import { RouterProvider } from 'react-router-dom';
import { HigherOrderComponent } from 'src/types/higher-order-component';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { LoadingComponent } from 'src/components/loading';
import { useAppAuth } from 'src/context/use-app-auth';
import { createRoutes } from 'src/routes';
import { NotFound } from './not-found';

const ForegroundComponent: React.FC = () => {
  const { isLoading, isUserLoggedIn, hasAuthError } = useAppAuth();

  if (hasAuthError) {
    return <NotFound />;
  }

  const routes = createRoutes(isUserLoggedIn);

  return conditionalRender(
    isLoading,
    <LoadingComponent />,
    <RouterProvider router={routes} />,
  );
};

const higherOrderComponents: HigherOrderComponent[] = [];
const ForegroundApp: React.FC = flowRight(higherOrderComponents)(
  ForegroundComponent,
);

export { ForegroundApp };
