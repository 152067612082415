import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { brand } from 'src/themes/palette';

const LoadingComponent: React.FC = () => {
  return (
    <Box
      flex={1}
      display="flex"
      height={'100vh'}
      alignItems="center"
      justifyContent="center"
      bgcolor={brand.background}>
      <CircularProgress color="secondary" />
    </Box>
  );
};

export { LoadingComponent };
