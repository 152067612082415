import React from 'react';
import { Box } from '@mui/material';
import { container } from './styles';
import { Screen } from 'src/components/screen';
import { DashboardHeader } from '../components/header/header';
import { KeywordsStatsList } from './keyword-stats';
import { ReviewStatsList } from './review-stats';
import { MetricsChart } from './performance-stats';

const KeywordsScreen: React.FC = () => {
  return (
    <Screen>
      <Box sx={container}>
        <DashboardHeader selection={0} />
        <Box>
          <KeywordsStatsList />
          <ReviewStatsList />
          <MetricsChart />
        </Box>
      </Box>
    </Screen>
  );
};

export { KeywordsScreen };
