import { StarRating } from 'src/api/types/types';

function getNumericStarRating(starRating: StarRating | string): number {
  let rating = StarRating.STAR_RATING_UNSPECIFIED;
  if (Object.values(StarRating).includes(starRating as StarRating)) {
    rating = starRating as StarRating;
  }

  switch (rating) {
    case StarRating.ONE:
      return 1;
    case StarRating.TWO:
      return 2;
    case StarRating.THREE:
      return 3;
    case StarRating.FOUR:
      return 4;
    case StarRating.FIVE:
      return 5;
    default:
      return 0; // or handle the unspecified case as needed
  }
}

export { getNumericStarRating };
