import { SxProps } from '@mui/material';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  backgroundColor: brand.tertiary,
  height: 40,
  width: 105,
  paddingRight: 2,
  borderRadius: 10,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
};

export { container };
