import React, { PropsWithChildren, useContext } from 'react';

type ContextValue = {
  isLoading: boolean;
};

export const Context = React.createContext<ContextValue>({ isLoading: false });

export const DashboardProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isLoading = false; //[isLoading, setIsLoading] = useState(false);
  // setIsLoading(false);

  return <Context.Provider value={{ isLoading }}>{children}</Context.Provider>;
};

export const useDashboard = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useGridOverlay must be wrapped within GridOverlayProvider',
    );
  }

  return context;
};
