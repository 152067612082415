import { useEffect, useState, useCallback } from 'react';
import { Review } from '../types/types';
import { axiosInstance } from '../client';
import { BASE_URL } from 'src/app/environment';
import { ApiResponse } from '../types/api-types';
import { ReviewCollection } from '../collections';
import { fetchCollectionData } from '../firebase-utils';
import { orderBy, where } from 'firebase/firestore';
import { useAppAuth } from 'src/context/use-app-auth';

type Props = {
  locationId?: string;
  fetchData?: boolean;
};

type AddReviewProps = {
  reviewText: string;
  reviewId: string;
  locationId: string;
  userId: string;
};

interface ReviewsDataResponse {
  reviews: Review[];
  loading: boolean;
  addReview: (params: AddReviewProps) => Promise<boolean>;
  revalidate: () => void;
  resetReviews: () => void;
}

const useReviews = ({
  locationId,
  fetchData = true,
}: Props): ReviewsDataResponse => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(false);
  const [shouldRevalidate, setShouldRevalidate] = useState(false);

  const { user } = useAppAuth();

  const fetchReviews = useCallback(() => {
    if (!locationId || !fetchData) return;

    setLoading(true);
    const collectionId = `${ReviewCollection}/${locationId}/data/`;
    const { unsubscribe } = fetchCollectionData<Review>(
      collectionId,
      (data: Review[]) => {
        setReviews(data);
        setLoading(false);
      },
      [
        where('hasReply', '==', false),
        orderBy('updateTime', 'desc'),
        // limit(10),
      ],
    );

    return () => unsubscribe?.();
  }, [locationId, fetchData]);

  useEffect(() => {
    if (fetchData) {
      fetchReviews();
    }
  }, [fetchReviews, shouldRevalidate]);

  // Add review function
  const addReview = async (params: AddReviewProps) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post<ApiResponse>(
        `${BASE_URL}/users/addReview`,
        params,
      );
      setLoading(false);
      if (response.data.success) {
        setShouldRevalidate(prev => !prev);
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    return false;
  };

  const revalidate = () => {
    setShouldRevalidate(prev => !prev);
  };

  const resetReviews = async () => {
    try {
      if (!user?.key) {
        return false;
      }

      const response = await axiosInstance.post<ApiResponse>(
        `${BASE_URL}/users/updateLocationReviews`,
        { locationId, userId: user.key },
      );
      setLoading(false);
      if (response.data.success) {
        setShouldRevalidate(prev => !prev);
        return true;
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
    return false;
  };

  return { loading, reviews, addReview, revalidate, resetReviews };
};

export { useReviews };
