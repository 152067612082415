import React, { useRef } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { container } from './styles';
import { ReactComponent as IconDown } from 'src/assets/svg/chevron-down.svg';
import { AppStateID, useAppAuth } from 'src/context/use-app-auth';

const LanguageSelect: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const { language, updateState } = useAppAuth();

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    updateState(AppStateID.Language, event.target.value);
  };

  return (
    <Select
      ref={ref}
      sx={container}
      value={language}
      onClick={() => ref.current?.focus()}
      IconComponent={() => <IconDown width={50} height={50} />}
      onChange={handleLanguageChange}>
      <MenuItem value={'en'}>EN</MenuItem>
      <MenuItem value={'it'}>IT</MenuItem>
    </Select>
  );
};

export { LanguageSelect };
