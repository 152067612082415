import { SxProps } from '@mui/material/styles';

const root: SxProps = {
  height: '100vh',
  position: 'absolute',
  display: 'flex',
  zIndex: 0,
  width: '100%',
};

const container: SxProps = {
  flex: 1,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const contentContainer: SxProps = {
  ...container,
  flexDirection: 'column',
};

const title: SxProps = {
  fontSize: '48px',
  lineHeight: '49px',
  fontWeight: 400,
};

const detail: SxProps = {
  fontSize: '20px',
  lineHeight: '21px',
  fontWeight: 400,
  marginTop: 3,
};

const contactUs: SxProps = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
};
const googleBtn: SxProps = {
  marginBottom: 5,
  marginTop: 10,
};

export {
  container,
  contentContainer,
  title,
  detail,
  contactUs,
  googleBtn,
  root,
};
