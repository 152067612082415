import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'src/text';
import { Tabs } from 'src/components/tabs';
import { useNavigate } from 'react-router-dom';
import { getStyles } from './styles';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { LocationSelect } from 'src/components/location-select';

type Props = {
  selection: number;
};

const DashboardHeader: React.FC<Props> = ({ selection }) => {
  const navigate = useNavigate();

  const { isMobile } = useWindowDimensions();
  const styles = getStyles(isMobile);

  const { dashboardLabels } = useTranslation();
  const tabLabels = [
    dashboardLabels.keywords,
    dashboardLabels.toDo,
    dashboardLabels.reviews,
  ];

  const handleSelection = (index: number) => {
    if (index === 1) {
      navigate('/home/todo');
    } else if (index === 0) {
      navigate('/home/keywords');
    } else {
      navigate('/home/reviews');
    }
  };

  return (
    <Box sx={styles.container}>
      <Tabs
        labels={tabLabels}
        selection={selection}
        onSelected={handleSelection}
      />
      {!isMobile && <LocationSelect />}
    </Box>
  );
};

export { DashboardHeader };
