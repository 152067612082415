import { useEffect, useState } from 'react';
import {
  ChecklistCollection,
  MustChecklistCollection,
  EssentialChecklistCollection,
} from '../collections';
import { ChecklistItem } from '../types/types';
import { fetchCollectionData } from '../firebase-utils';

type Props = {
  locationId?: string;
};

interface ChecklistDataResponse {
  strength: number;
  essentialChecklist: ChecklistItem[];
  mustChecklist: ChecklistItem[];
}

const useChecklists = ({ locationId }: Props): ChecklistDataResponse => {
  const [strength, setStrength] = useState<number>(0);
  const [mustChecklist, setMustChecklist] = useState<ChecklistItem[]>([]);
  const [essentialChecklist, setEssentialChecklist] = useState<ChecklistItem[]>(
    [],
  );
  useEffect(() => {
    const totalCheckedItems =
      mustChecklist.filter(item => item.checked).length +
      essentialChecklist.filter(item => item.checked).length;

    setStrength(totalCheckedItems);
  }, [mustChecklist, essentialChecklist]);

  useEffect(() => {
    if (!locationId) {
      return;
    }

    const essentialCollectionId = `${ChecklistCollection}/${locationId}/${EssentialChecklistCollection}`;
    const { unsubscribe: essentialUnSubscribe } =
      fetchCollectionData<ChecklistItem>(
        essentialCollectionId,
        (data: ChecklistItem[]) => {
          setEssentialChecklist(data);
        },
      );

    const mustCollectionId = `${ChecklistCollection}/${locationId}/${MustChecklistCollection}`;
    const { unsubscribe: mustUnSubscribe } = fetchCollectionData<ChecklistItem>(
      mustCollectionId,
      (data: ChecklistItem[]) => {
        setMustChecklist(data);
      },
    );

    return () => {
      mustUnSubscribe?.();
      essentialUnSubscribe?.();
    };
  }, [locationId]);

  return { strength, essentialChecklist, mustChecklist };
};

export { useChecklists };
