import axios from 'axios';
import { MetricsAPIResponse } from '../types/api-types';
import { MetricsData } from '../hooks/use-metrics';

export const fetchMultiDailyMetrics = async (
  locationId: string,
  accessToken: string,
  startDate: Date,
  endDate: Date,
): Promise<MetricsData | undefined> => {
  if (!locationId || !accessToken) {
    throw new Error('Invalid authentication parameters');
  }
  // Format start and end dates
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth() + 1;
  const startDay = startDate.getDate();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth() + 1;
  const endDay = endDate.getDate();

  const endpoint =
    `https://businessprofileperformance.googleapis.com/v1/locations/${locationId}:fetchMultiDailyMetricsTimeSeries?` +
    `dailyMetrics=WEBSITE_CLICKS&dailyMetrics=CALL_CLICKS&dailyMetrics=BUSINESS_DIRECTION_REQUESTS&dailyMetrics=BUSINESS_IMPRESSIONS_MOBILE_MAPS&` +
    `dailyRange.start_date.year=${startYear}&dailyRange.start_date.month=${startMonth}&dailyRange.start_date.day=${startDay}&` +
    `dailyRange.end_date.year=${endYear}&dailyRange.end_date.month=${endMonth}&dailyRange.end_date.day=${endDay}`;

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  // Make the request
  const response = await axios.get<MetricsAPIResponse>(endpoint, { headers });

  // Check response status
  if (response.status === 200) {
    const data = response.data.multiDailyMetricTimeSeries;

    if (data && data.length > 0) {
      const dailySeries = data[0].dailyMetricTimeSeries;
      const callClicks = dailySeries.filter(
        series => series.dailyMetric === 'CALL_CLICKS',
      )?.[0];
      const websiteClicks = dailySeries.filter(
        series => series.dailyMetric === 'WEBSITE_CLICKS',
      )?.[0];
      const directionClicks = dailySeries.filter(
        series => series.dailyMetric === 'BUSINESS_DIRECTION_REQUESTS',
      )?.[0];
      const mobileClicks = dailySeries.filter(
        series => series.dailyMetric === 'BUSINESS_IMPRESSIONS_MOBILE_MAPS',
      )?.[0];

      return {
        callClicks: callClicks.timeSeries,
        mobileClicks: mobileClicks.timeSeries,
        websiteClicks: websiteClicks.timeSeries,
        directionClicks: directionClicks.timeSeries,
      };
    }
  }
  return undefined;
};
