import React from 'react';
import { Box, Button } from '@mui/material';
import { button } from './styles';
import { conditionalRender } from 'src/utils/ui/conditional-render';

import { authService } from 'src/api/services/auth-service';
import { useAppAuth } from 'src/context/use-app-auth';
import { useTranslation } from 'src/text';
import { CONTACT_US_URL, PRIVACY_URL, TERMS_URL } from 'src/app/constants';
import { alertLabels } from 'src/text/locales/default-en';

const HeaderButtonsList = () => {
  const { isUserLoggedIn } = useAppAuth();
  const { genericLabels } = useTranslation();

  const handleContactPressed = () => {
    window.open(CONTACT_US_URL, '_blank');
  };

  const handleTermsPressed = () => {
    window.open(TERMS_URL, '_blank');
  };

  const handlePrivacyPressed = () => {
    window.open(PRIVACY_URL, '_blank');
  };

  const handleLogoutPressed = () => {
    const confirmSignOut = window.confirm(alertLabels.signout);
    if (confirmSignOut) {
      authService.signOut();
    }
  };

  return (
    <Box>
      <Button sx={button} onClick={handleContactPressed}>
        {genericLabels.contactUs}
      </Button>
      {conditionalRender(
        isUserLoggedIn,
        <Button sx={button} color="secondary" onClick={handleLogoutPressed}>
          {genericLabels.logout}
        </Button>,
      )}
      <Button sx={button} onClick={handleTermsPressed}>
        {genericLabels.terms}
      </Button>
      <Button sx={button} onClick={handlePrivacyPressed}>
        {genericLabels.privacy}
      </Button>
    </Box>
  );
};

export { HeaderButtonsList };
