import { auth, firestoreDb } from './client';
import {
  AddDocumentResult,
  FetchListResult,
  FetchResult,
  Role,
  SimplyCityUser,
  UpdateResult,
  UpdateResultListener,
} from './types/types';
import {
  collection,
  getDoc,
  doc,
  getDocs,
  writeBatch,
  setDoc,
  addDoc,
  updateDoc,
  CollectionReference,
  QuerySnapshot,
  onSnapshot,
  deleteDoc,
  query,
  QueryConstraint,
} from 'firebase/firestore';
import {
  AdminsCollection,
  LocationsCollection,
  UsersCollection,
} from './collections';
import { BusinessLocation } from './types/business-types';
import { User } from 'firebase/auth';

type KeyProps = {
  key: string;
};

async function fetchUserRoleAndData(user: User): Promise<SimplyCityUser> {
  const adminDoc = await getDoc(
    doc(firestoreDb, `${AdminsCollection}/${user.uid}`),
  );
  if (adminDoc.exists()) {
    const user = { ...(adminDoc.data() as SimplyCityUser), role: Role.admin };
    if (user.enabled) {
      return user;
    }
  }

  const userDoc = await getDoc(
    doc(firestoreDb, `${UsersCollection}/${user.uid}`),
  );
  if (userDoc.exists()) {
    const user = { ...(userDoc.data() as SimplyCityUser), role: Role.customer };
    if (user.enabled) {
      return user;
    }
  }

  throw new Error('User not found');
}

async function fetchLocations(
  role: Role,
  uid?: string,
): Promise<BusinessLocation[]> {
  const collectionPath =
    role === Role.admin
      ? `${LocationsCollection}`
      : `${UsersCollection}/${uid}/${LocationsCollection}`;
  const result = await fetchDocuments<BusinessLocation>(collectionPath);
  return result?.data || [];
}

const updateDocuments = async <T extends KeyProps>(
  collectionId: string,
  data: T[],
): Promise<UpdateResult> => {
  try {
    const user = auth.currentUser;
    const id = user?.uid;
    if (!id) {
      return {
        success: false,
        message: 'Not authorized',
      };
    }

    const batch = writeBatch(firestoreDb);
    const collectionRef = collection(firestoreDb, collectionId);
    data.forEach(item => {
      const docRef = doc(firestoreDb, `${collectionRef.path}/${item.key}`);
      batch.set(docRef, item, { merge: true });
    });

    await batch.commit();
    return {
      success: true,
      message: 'Documents successfully updated',
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed to update documents',
    };
  }
};

const fetchCollectionData = <T>(
  collectionId: string,
  // eslint-disable-next-line no-unused-vars
  callback: (data: T[]) => void,
  filters: QueryConstraint[] = [],
): UpdateResultListener => {
  const user = auth.currentUser;
  if (!user) {
    return {
      success: false,
      message: 'Not authorized',
    };
  }

  const collectionRef: CollectionReference = collection(
    firestoreDb,
    collectionId,
  );
  // Need to pass a custom imoplementhat to collectionRef as optional
  // Base query with ordering and limit
  const baseQuery = query(collectionRef, ...filters);

  return {
    success: true,
    message: '',
    unsubscribe: onSnapshot(baseQuery, (snapshot: QuerySnapshot) => {
      const data: T[] = [];
      snapshot.forEach(doc => {
        data.push(doc.data() as T);
      });
      callback(data);
    }),
  };
};

const fetchDocuments = async <T>(
  collectionId: string,
): Promise<FetchListResult<T>> => {
  const user = auth.currentUser;
  if (!user) {
    return {
      success: false,
      message: 'Not authorized',
    };
  }

  const docSnapshot = await getDocs(collection(firestoreDb, collectionId));
  if (!docSnapshot.empty) {
    const data: T[] = [];
    docSnapshot.forEach(doc => {
      data.push(doc.data() as T);
    });
    return {
      success: true,
      data: data,
    };
  } else {
    return {
      success: false,
      message: 'No documents found',
      data: [],
    };
  }
};

const fetchDocument = async <T>(
  collectionId: string,
  documentId: string,
): Promise<FetchResult<T>> => {
  const user = auth.currentUser;
  if (!user) {
    return {
      success: false,
      message: 'Not authorized',
    };
  }

  const docSnapshot = await getDoc(
    doc(firestoreDb, `${collectionId}/${documentId}`),
  );
  if (docSnapshot.exists()) {
    return {
      success: true,
      data: docSnapshot.data as T,
    };
  } else {
    return {
      success: false,
      message: 'No documents found',
    };
  }
};

const addDocument = async <T>(
  data: Record<string, T>,
  collectionId: string,
  documentId?: string,
): Promise<AddDocumentResult> => {
  let addedDocumentId = documentId ?? '';
  if (documentId) {
    await setDoc(doc(firestoreDb, `${collectionId}/${documentId}`), {
      ...data,
      key: documentId,
    });
  } else {
    const collectionRef = collection(firestoreDb, `${collectionId}`);
    const docRef = await addDoc(collectionRef, data);
    await updateDoc(docRef, { key: docRef.id });
    addedDocumentId = docRef.id;
  }
  return {
    success: true,
    message: '',
    documentId: addedDocumentId,
  };
};

const updateDocument = async <T>(
  data: Record<string, T>,
  documentId: string,
): Promise<UpdateResult> => {
  try {
    const user = auth.currentUser;
    const id = user?.uid;
    if (!id) {
      return {
        success: false,
        message: 'Not authorized',
      };
    }

    const documentRef = doc(firestoreDb, documentId);
    await updateDoc(documentRef, data);

    return {
      success: true,
      message: 'Documents successfully updated',
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed to update documents',
    };
  }
};

const deleteDocument = async (
  documentId: string,
  collectionId: string,
): Promise<UpdateResult> => {
  try {
    const user = auth.currentUser;
    const id = user?.uid;
    if (!id) {
      return {
        success: false,
        message: 'Not authorized',
      };
    }

    const documentRef = doc(firestoreDb, `${collectionId}/${documentId}`);
    await deleteDoc(documentRef);
    return {
      success: true,
      message: 'Documents deleted updated',
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed to update documents',
    };
  }
};

export {
  addDocument,
  updateDocument,
  updateDocuments,
  fetchDocument,
  fetchDocuments,
  fetchCollectionData,
  fetchUserRoleAndData,
  fetchLocations,
  deleteDocument,
};
