import { DataType } from '..';

const notFoundLabels = {
  emailUs: 'Email us at hello@thesimply.city to apply for a test account',
  waitlist: 'Join the waitlist',
};

const genericLabels = {
  contactUs: 'Contact Us',
  logout: 'Logout',
  month: 'Month',
  na: 'N/A',
  add: '+ Add',
  terms: 'T & C',
  privacy: 'Privacy Policy',
};

const loginLabels = {
  signIn: 'Sign in',
  detail: 'With your business google account',
  new: 'New to SimplyCity?',
};

const dashboardLabels = {
  keywords: 'Keywords',
  toDo: 'To-Do List',
  reviews: 'Reviews',
  businessLocation: 'Business Location',
};

const keywordLabels = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  delete: 'Delete Keyword',
  typeKeyword: 'Type Keyword',
  overallStrength: 'Over all profile strength',
  reviewStats: 'Review Statistics',
  rankings: 'Keywords Rankings',
  description:
    'Keywords for local SEO for a place can vary depending on the specific location, offerings, and target audience. These are some of the keywords found relevant to your business. Keeping track of the progress is key in our system 👍',
  processingKeywords: 'Processing Keywords',
  researchKeywords:
    'The keywords for your businesses are still being researched by the simiplicty team',
};

const statsLabels = {
  movementInfo: '(since first login)',
  competitorsText: (count: DataType) => `Out of ${count} local competitors`,
  placesRankingText: (ranking: DataType) => `${ranking} places`,
};

const reviewLabels = {
  title: 'Reply Recent Reviews',
  stars: 'Stars',
  generateReply: 'Generate Reply',
};

const todoLabels = {
  edit: 'Edit item',
  delete: 'Delete item',
  progress: 'Your checklist progress',
  essentialInfo: 'Essential Information',
  mustHaves: 'SimplyCity Must Haves',
  addChecklist: 'Type checklist item',
};

const alertLabels = {
  signout: 'Are you sure you want to sign out?',
  deleteToDo: (title: DataType) =>
    `Are you sure, you want to delete "${title}"`,
  deleteKeyword: (keyword: DataType) =>
    `Are you sure, you want to delete ${keyword} keyword`,
};

const chartLabels = {};

const spanishTranslations = {
  chartLabels,
  reviewLabels,
  notFoundLabels,
  alertLabels,
  statsLabels,
  genericLabels,
  loginLabels,
  dashboardLabels,
  keywordLabels,
  todoLabels,
};

export default spanishTranslations;
