import { SxProps } from '@mui/material';
import { GLOBAL_MARGIN_HORIZONTAL } from 'src/app/constants';

const getStyles = (isMobile: boolean): { container: SxProps } => ({
  container: {
    marginTop: isMobile ? GLOBAL_MARGIN_HORIZONTAL : 0,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : 'row',
  },
});

export { getStyles };
