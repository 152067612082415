import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { GoogleButton } from 'src/components/google-btn';
import { Screen } from 'src/components/screen';

import {
  container,
  title,
  contentContainer,
  detail,
  contactUs,
  googleBtn,
  root,
} from './styles';
import { useTranslation } from 'src/text';
import { t2 } from 'src/themes/custom';

const LoginScreen: React.FC = () => {
  const { genericLabels, loginLabels } = useTranslation();

  const handleContactUsPressed = () => {};
  return (
    <Screen contentContainerStyle={root}>
      <Box sx={container}>
        <Typography />
        <Box sx={contentContainer}>
          <Typography sx={title}>{loginLabels.signIn}</Typography>
          <Typography sx={detail}>{loginLabels.detail}</Typography>
          <Box sx={googleBtn}>
            <GoogleButton />
          </Box>
          <Box sx={contactUs}>
            <Typography sx={t2}>{loginLabels.new}</Typography>
            <Button
              variant="text"
              color="secondary"
              onClick={handleContactUsPressed}>
              <Typography color={'secondary'} sx={t2}>
                {genericLabels.contactUs}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};

export { LoginScreen };
