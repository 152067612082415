import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { addButton, container, addKeywordField } from './styles';
import { useTranslation } from 'src/text';

type AddProps = {
  label: string;
  allowSpaces?: boolean;
  // eslint-disable-next-line no-unused-vars
  onAdd: (value: string) => void;
};

const AddFieldComponent: React.FC<AddProps> = ({
  label,
  onAdd,
  allowSpaces = false,
}) => {
  const [value, setValue] = useState('');
  const { genericLabels } = useTranslation();

  const handleAddChange = (event: { target: { value: string } }) => {
    if (allowSpaces) {
      setValue(event.target.value ?? '');
      return;
    }

    const val = event.target.value.replace(' ', '');
    setValue(val);
  };

  const handleAddPressed = async () => {
    if (!value) {
      return;
    }
    onAdd(value);
    setValue('');
  };

  return (
    <Box mt={2} sx={container}>
      <TextField
        sx={addKeywordField}
        value={value}
        label={label}
        onChange={handleAddChange}
      />
      <Button onClick={handleAddPressed} sx={addButton}>
        {genericLabels.add}
      </Button>
    </Box>
  );
};

export { AddFieldComponent };
