import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
} from '@mui/material';
import { ChecklistItem } from 'src/api/types/types';
import {
  progress,
  container,
  replyButton,
  cancelButton,
  replyTextField,
  contentContainer,
  replyButtonContainer,
} from './styles';
import { genericLabels, todoLabels } from 'src/text/locales/default-en';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { useAppAuth } from 'src/context/use-app-auth';
import { useAddData } from 'src/api/hooks/use-add-data';

type Props = {
  item: ChecklistItem;
  collectionId: string;
  onClose: () => void;
};

const ChecklistDialog: React.FC<Props> = ({ item, collectionId, onClose }) => {
  const { location, user } = useAppAuth();
  const { isLoading, updateToDo } = useAddData();

  const [title, setTitle] = useState(item.title);

  const handleTitleChange = (event: { target: { value: string } }) => {
    setTitle(event.target.value);
  };

  const handleOnSave = async () => {
    if (!location?.key || !user) {
      return;
    }

    await updateToDo({
      title,
      isChecked: item.checked,
      todoDocumentId: item.key,
      todoCollectionId: collectionId,
      locationId: location.key,
    });
  };

  return (
    <Dialog fullWidth sx={container} open={true} onClose={onClose}>
      <DialogContent sx={contentContainer}>
        <TextField
          focused
          sx={replyTextField}
          value={title}
          label={todoLabels.edit}
          onChange={handleTitleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={cancelButton} onClick={onClose}>
          {genericLabels.cancel}
        </Button>
        <Box sx={replyButtonContainer}>
          {conditionalRender(
            isLoading,
            <CircularProgress size={20} sx={progress} />,
            <Button sx={replyButton} onClick={() => handleOnSave()}>
              {genericLabels.save}
            </Button>,
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { ChecklistDialog };
