import { Box, Typography } from '@mui/material';
import React from 'react';
import { container, icon, selected, tab } from './styles';
import { Check } from '@mui/icons-material';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { mergeStyles } from 'src/utils/ui/merge-styles';
import { l1 } from 'src/themes/custom';

type Props = {
  labels: string[];
  selection: number;
  // eslint-disable-next-line no-unused-vars
  onSelected: (index: number) => void;
};

const Tabs: React.FC<Props> = ({ labels, selection, onSelected }) => {
  return (
    <Box
      border={1}
      borderRadius={10}
      borderColor={'primary.secondary'}
      sx={container}>
      {labels.map((label, index) => (
        <Box
          onClick={() => onSelected(index)}
          key={`${label}${index}`}
          borderRight={index < labels.length - 1 ? 1 : 0}
          borderColor={'primary.light'}
          sx={mergeStyles(tab, selection === index ? selected : undefined)}>
          {conditionalRender(
            selection === index,
            <Check width={20} height={20} color="primary" sx={icon} />,
          )}
          <Typography sx={l1}>{label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export { Tabs };
