import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './themes/default';
import { GridOverlayProvider } from './context/use-grid-overlay';
import { ForegroundApp } from './presentation/forground-app';
import { AuthProvider } from './context/use-app-auth';

// https://www.figma.com/file/awFkcwdvpE2NghMnmoKZte/SimplyCity-Saas-Dashboard?type=design&node-id=47909%3A2&mode=design&t=LID8z6fdvs1EGCCR-1
const App: React.FC = () => {
  return (
    <GridOverlayProvider>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ForegroundApp />
        </AuthProvider>
      </ThemeProvider>
    </GridOverlayProvider>
  );
};
export default App;
